/**
 * Button
 */

.wp-block-button__link {
  all: revert;
  background: rgb(221, 13, 109);
  background: linear-gradient(90deg, rgba(221, 13, 109, 1) 35%, rgba(233, 81, 30, 1) 100%);
  border-radius: 12px;
  padding: 1.2rem 3rem;
  min-width: 150px;
  min-height: 35px;
  font-weight: 800;
  color: #fff;
}
