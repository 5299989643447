.site-footer {
  background: color('primary');
  color: color('secondary');

  a {
    color: color('secondary');
  }
}

footer .main-logo-link {
  align-items: start;
}

footer {
  p,
  a,
  tr,
  td {
    line-height: 2 !important;
  }

  ul {
    list-style-type: none;
    margin-top: 0;
  }

  table tr th:nth-child(1) {
    padding-right: 14px;
  }
}
