/**
 * Header
 */

.site-header {
  background: color('primary');

  a {
    color: #fff !important;
  }
}

// Logo size
.main-logo-link {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 91px;
  padding-top: 9px;

  @media (min-width: breakpoint(sm)) {
    max-width: 170px;
  }
}

.menu-item {
  &.extra-menu-color {
    background: color('secondary');
  }
}

.title {
  bottom: 1rem;
  left: 1rem;
  max-width: 700px;
  text-shadow: -1px 0 1px black;
  line-height: 1.2;

  @media (min-width: breakpoint(sm)) {
    bottom: 5rem;
    left: 7rem;
  }

  @media (min-width: breakpoint(xl)) {
    bottom: 10rem;
    left: 10rem;
  }
}

nav {
  ul {
    list-style-type: none;
    font-size: 1.2rem;

    li {
      font-weight: 800;
    }
  }

  .main-logo {
    width: 100%;
  }

  .menu-content {
    height: 2rem;
    @media (max-width: breakpoint(sm)) {
      height: 2rem;
    }

    // Logo size
    .main-logo {
      max-width: 150px;
      height: auto;
      @media (max-width: breakpoint(sm)) {
        max-width: 50px;
      }
    }
  }
}

.page-startseite nav {
  margin-right: 3%;
  margin-left: 5%;
  animation: slide-in-left 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  min-width: 250px;
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.mobile-menu {
  position: fixed;
  background-color: color('primary');
  z-index: 1000;
  font-family: 'Raleway', sans-serif;
  transform: translate(-4000px);
  width: 100%;
  height: 100%;
  top: 0;

  .menu-content {
    margin-top: 3.5rem;
    justify-content: space-between;

    ul {
      margin-left: 15vw;
      list-style-type: none;
      margin-right: 5vw;

      li {
        position: relative;
      }
    }
  }

  .meta-nav {
    margin-left: 15vw;
    margin-top: 3rem;

    a {
      padding-right: 2rem;
    }
  }
}

.show {
  transform: translate(1px);
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.z-10000 {
  z-index: 1500;
}

.overflow {
  overflow: hidden;
}

.mobile-logo {
  padding-top: 4px;
  align-self: baseline;

  @media (min-width: breakpoint(sm)) {
    padding-top: 1.5rem;
  }
}

.menu-btn {
  top: 0;
  left: 0;
  max-width: 100%;
  margin: -6rem 0 0 auto;
  align-self: baseline;

  @media (min-width: breakpoint(sm)) {
    max-width: 240px;
  }
}
