// **
// * Variables
// **

$colors: (
  black: #222,
  white: #fff,
  primary: #1ea0a0,
  secondary: #9d9d9f,
  grey: #f5f5f5,
);
$font-family-base: 'Helvetica Neue', sans-serif;

$breakpoints: (
  'xs': 600px,
  'sm': 782px,
  'md': 1024px,
  'lg': 1220px,
  'xl': 1460px,
);

/**
* Igniter Utilities settings
*/
$grid-active: true; // include grid container and column classes
$helper-zindex: true;
$helper-positions: true;
$helper-border: true;
$spacer: (
  '0': 0,
  'xs': 0.25rem,
  'sm': 0.5rem,
  '1': 1rem,
  '2': 2rem,
  '3': 3rem,
  '4': 4rem,
  '5': 5rem,
  '6': 6rem,
  '7': 7rem,
  '8': 8rem,
  '9': 9rem,
  '10': 10rem,
  '15': 15rem,
  '20': 20rem,
  '30': 30rem,
  '40': 40rem,
  '50': 50rem,
  '60': 60rem,
  '70': 70rem,
  '80': 80rem,
  '90': 90rem,
  '100': 100rem,
);
