/**
 * Global
 */

* {
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
}

a {
  color: #fff;
  text-decoration: none !important;
  background-color: transparent;

  &:hover {
    opacity: 0.85;
    transform: scale(1.1) !important;
  }
}

svg,
img,
embed,
object {
  display: block;
  height: auto;
  max-width: 100%;
}

section,
.section {
  padding: 2rem 1rem 4rem 1rem;
  margin: auto;

  @media (min-width: breakpoint(sm)) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 0;
  }

  @media (min-width: breakpoint(lg)) {
    padding-left: 11rem;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    margin: auto;
  }
}

.section-x {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  @media (min-width: breakpoint(sm)) {
    margin-left: 11rem;
    margin-right: 5rem;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    margin: auto;
  }
}

.section-full {
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  @media (min-width: breakpoint(md)) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    padding-left: 3rem;
    padding-right: 0;
  }
}

.section-footer {
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  @media (min-width: breakpoint(md)) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    padding-left: 0;
    padding-right: 0;
  }
}

h2 {
  line-height: 1.2;
}

.-ml-18 {
  margin-left: 0;

  @media (min-width: breakpoint(md)) {
    margin-left: -18rem;
  }
}

.w-360 {
  max-width: 360px;
}

.-mr-16 {
  margin-right: 0;

  @media (min-width: breakpoint(md)) {
    margin-right: -16rem;
  }
}

.mw-700 {
  max-width: 700px;
}

.mw-500 {
  max-width: 500px;
}

.header-img {
  img {
    @media (min-width: breakpoint(sm)) {
      margin-left: -7rem;
      margin-bottom: -5rem;
    }
  }
}

.header-img-team {
  img {
    @media (min-width: breakpoint(sm)) {
      margin-left: -8rem;
      margin-bottom: -5rem;
    }
  }
}

.hide {
  display: none !important;
}

.logos {
  background-color: #707070;
  border-radius: 15px;
}

.has-pink-background-color {
  background-color: #d62d41;
}

.gradient {
  background: rgb(221, 13, 109);
  background: linear-gradient(90deg, rgba(221, 13, 109, 1) 35%, rgba(233, 81, 30, 1) 100%);
}

.gallery-m {
  img {
    margin: 0 0 0 auto;
  }
}

.rm-gradient {
  a {
    background: linear-gradient(45deg, black, black);
  }
}

.pl-17 {
  padding-left: 17px;
}

.img-auto {
  img {
    margin: auto;
  }
}

.rc-anchor .rc-anchor-invisible .rc-anchor-light  .rc-anchor-invisible-hover {
  display: none;
}
