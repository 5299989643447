/**
* Gutenberg Color helper classes
* - rememer to set colors for backend
*/
@each $color-name, $color-value in $colors {
  .has-#{'' + $color-name}-color {
    color: $color-value;
  }

  .has-#{'' + $color-name}-background-color {
    background-color: $color-value;
  }
}

/**
* Container settings for Gutenberg Markup
*/

// force center for some gutenberg blocks

.alignfull {
  max-width: 100%;
}

/**
* Gutenberg Blocks Styling
*/
