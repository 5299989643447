// Contact-Form 7
.wpcf7 {
  max-width: 20em;

  input[type=text],
  input[type=email],
  textarea {
    border: 1px solid color('primary');
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  input[type=submit] {
    border: 0;
    background: color('primary');
    color: white;
    padding: 0.75em 1em;
    margin-top: 10px;

    &:disabled {
      background: color('secondary');
    }
  }

  .wpcf7-list-item {
    margin: 0;
  }

  a {
    color: color('primary');
  }
}
