// *
// * Include Fonts and define basic font ajustments and styling
// *

$fonts-path: '../fonts';

/* fira-sans-regular - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/fira-sans-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/fira-sans-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/fira-sans-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/fira-sans-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/fira-sans-v11-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-800 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/fira-sans-v11-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/fira-sans-v11-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/fira-sans-v11-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/fira-sans-v11-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/fira-sans-v11-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/fira-sans-v11-latin-800.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/raleway-v19-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/raleway-v19-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/raleway-v19-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/raleway-v19-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/raleway-v19-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}

/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/raleway-v19-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/raleway-v19-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/raleway-v19-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/raleway-v19-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/raleway-v19-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/raleway-v19-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
}

.font-brand {
  font-family: 'Fira Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 .raleway {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 800 !important;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

p {
  line-height: 1.7;
  font-family: 'Fira Sans', sans-serif;
}

.is-style-heading-size-1,
h1,
.heading-size-1 {
  @include font-size(5rem);

  @media (min-width: breakpoint(xl)) {
    font-size: 6rem;
  }
}

.is-style-heading-size-2,
.heading-size-2,
h2 {
  @media (max-width: breakpoint(sm)) {
    font-size: 1.5rem;
  }

  @include font-size(3.3rem);
}

.is-style-heading-size-3,
.heading-size-3 {
  @include font-size(1.5rem);
}

.is-style-heading-size-4,
.heading-size-4 {
  @include font-size(3rem);
}

.is-style-heading-size-5,
.heading-size-5 {
  @include font-size(2rem);
}

.is-style-heading-size-6,
.heading-size-6 {
  @include font-size(2.3rem);
}
