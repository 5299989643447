.rolle-lang,
.rolle,
.rolle-lang-head,
.rolle-black,
.rolle-menu,
.rolle-menu-hover,
.rolle-weiss,
.rolle-lang-kontakt {
  position: relative;
}

.rolle-menu::before,
.rolle-lang::before,
.menu-content li:hover::before,
.rolle-lang-kontakt::before,
.rolle-weiss::before,
.rolle::before,
.rolle-lang-head::before,
.rolle-black::before {
  position: absolute;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
}

.rolle-menu::before {
  background-image: url('../img/hodge_rolle_menu.svg');
  width: 43px;
  height: 25px;
  left: -31px;
  top: 13px;

  @media (min-width: breakpoint(md)) {
    width: 96px;
    height: 48px;
    left: -97px;
    top: -10px;
  }
}

.menu-content {
  li:hover::before {
    background-image: url('../img/hodge_rolle_black.svg');
    position: absolute;
    content: '';
    width: 129px;
    height: 63px;
    background-size: cover;
    left: -10rem;
    top: -10px;
  }
}

.rolle-lang::before {
  background-image: url('../img/hodge_druckerei_rolle_lang.svg');
  width: 162px;
  height: 31px;
  left: 0;
  top: -8rem;

  @media (min-width: breakpoint(md)) {
    width: 180px;
    height: 57px;
    left: -13rem;
    top: -3rem;
  }
}

.rolle-lang-kontakt::before {
  background-image: url('../img/rolle-lang-kontakt.svg');
  width: 180px;
  height: 57px;
  left: -2rem;
  top: -5px;
}

.rolle::before {
  background-image: url('../img/hodge_druckerei_rolle.svg');
  width: 88px;
  height: 31px;
  left: 0;
  top: -144px;

  @media (min-width: breakpoint(sm)) {
    width: 131px;
    height: 61px;
    left: -10rem;
    top: -2rem;
  }
}

.rolle-weiss::before {
  background-image: url('../img/rolle-weiss.svg');
  width: 131px;
  height: 60px;
  left: -10rem;
  top: -2rem;
}

.rolle-lang-head::before {
  background-image: url('../img/hodge_druckerei_rolle_lang.svg');
  width: 180px;
  height: 55px;
  left: -7rem;
  top: 0;
}

.rolle-black::before {
  background-image: url('../img/hodge_rolle_black.svg');
  width: 133px;
  height: 63px;
  left: 0;
  top: -5rem;

  @media (min-width: breakpoint(sm)) {
    left: -10rem;
    top: -2rem;
  }
}

.checkmark {
  margin-left: 24px !important;

  li {
    list-style-type: none;
    position: relative;
    line-height: 250%;
  }

  li::before {
    content: '';
    width: 15px;
    height: 32px;
    position: absolute;
    top: 7px;
    left: -1.5rem;
    background-image: url('../img/checkmark.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
