.z-1 {
  z-index: -1;
}

.uppercase {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.fw-800 {
  font-weight: 800;
}

.ml-12 {
  margin-left: 12rem;
}

.d-block-md {
  display: none;

  @media (min-width: breakpoint(md)) {
    display: block;
  }
}

.d-flex-md {
  display: none;

  @media (min-width: breakpoint(md)) {
    display: flex;
  }
}

.d-none-md {
  @media (min-width: breakpoint(md)) {
    display: none;
  }
}
